<template>
  <div class="mt-16">
    <main>
      <section>
        <div class="mx-auto w-full px-6 lg:w-4/5 select-none">
          <div
            class="grid grid-cols-1 lg:grid-cols-2 gap-8 justify-items-center items-center"
          >
            <div class="">
              <div class="font-normal md:font-bold text-3xl pb-4">O nás</div>
              <div class="font-normal text-lg text-justify">
                Bonbon pie icing toffee gummi bears. Marshmallow tart sugar plum
                cheesecake liquorice icing tiramisu bear claw oat cake. Dragée
                fruitcake jujubes macaroon jujubes lollipop bonbon jelly beans
                sugar plum. Apple pie caramels biscuit candy canes powder
                croissant. Liquorice marshmallow jelly-o sweet roll chupa chups
                topping cookie macaroon. Bear claw brownie bear claw lemon drops
                pudding. Soufflé cookie bear claw cake chocolate gingerbread.
                Apple pie sweet roll macaroon pie chocolate bar marzipan
                jujubes. Chocolate cake cake sweet caramels tootsie roll gummi
                bears gummies dessert tart. Apple pie cheesecake jelly beans
                biscuit tootsie roll tart. Jelly beans ice cream sweet roll bear
                claw sweet roll jujubes danish carrot cake fruitcake. Apple pie
                jelly-o jelly-o lollipop carrot cake. Croissant tootsie roll
                dessert lemon drops dragée.
              </div>
            </div>
            <div>
              <img
                src="../../public/images/about-us.png"
                class="pointer-events-none"
              />
            </div>
          </div>
          <div class="grid grid-cols-1 gap-4 justify-items-center my-20">
            <div class="font-normal md:font-bold text-3xl pb-4">Náš tým</div>
            <div class="px-6 text-center">
              Bonbon pie icing toffee gummi bears. Marshmallow tart sugar plum
              cheesecake liquorice icing tiramisu bear claw oat cake. Dragée
              fruitcake jujubes macaroon jujubes lollipop bonbon jelly beans
              sugar plum.
            </div>
          </div>
          <div
            class="grid grid-cols-1 sm:grid-cols-3 gap-16 justify-items-center mt-6"
          >
            <div>
              <div>
                <img
                  src="../../public/images/person1.png"
                  class="pointer-events-none rounded-tl-3xl"
                />
              </div>
              <div class="text-center text-lg pt-4">Andrea Pokorná</div>
              <div class="text-center text-gray-400 uppercase text-sm pt-1">
                Seo
              </div>
            </div>
            <div>
              <div class="grid justify-items-center">
                <img
                  src="../../public/images/person2.png"
                  class="pointer-events-none rounded-tl-3xl"
                />
              </div>
              <div class="text-center text-lg pt-4">Josef Marek</div>
              <div class="text-center text-gray-400 uppercase text-sm pt-1">
                Sales representative
              </div>
            </div>
            <div>
              <div>
                <img
                  src="../../public/images/person3.png"
                  class="pointer-events-none rounded-tl-3xl"
                />
              </div>
              <div class="text-center text-lg pt-4">Jan Novák</div>
              <div class="text-center text-gray-400 uppercase text-sm pt-1">
                Help desk
              </div>
            </div>
          </div>
          <div class="grid justify-items-center my-20">
            <div class="pb-1">
              <p class="text-left font-bold text-3xl pb-1">Bankovní údaje</p>
              <p class="text-left font-bold pt-3 pb-1">Obchodník</p>
              <p class="text-left pb-1">Bezmaklere s.r.o</p>
              <p class="text-left pb-1">Vrchlického 44-48</p>
              <p class="text-left pb-1">150 00 Praha 5</p>
              <p class="text-left pb-4">www.bezmaklere.cz</p>
              <p class="text-left font-bold">Číslo bankovního účtu</p>
              <p class="text-left pb-1">AirBank</p>
              <p class="text-left pb-1">1234567890/0000</p>
              <p class="text-left pb-1">SWIFT: 12345678900000123456789</p>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
